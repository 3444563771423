import axiosPrivateInstanceFunction from "../../common/helpers/customAxiosInstance";
import { BASE_URL } from "../../../config";
import {
  ACCESS_TOKEN_EXPIRE,
  FAILURE,
  NETWORK_ERROR,
  SOMETHING_WENT_WRONG,
  SUCCESS,
} from "../../common/constants";

/**
 * Fetches vehicle details from the server.
 *
 * @param {string} vehicleNumber - The number of the vehicle to fetch details for.
 * @returns {Promise<Object>} A promise that resolves to an object containing the status and either the vehicle data or an error message.
 */
const fetchVehicleDetails = async (vehicleNumber) => {
  return new Promise(async (fulfill) => {
    try {
      // Make a GET request to the vehicle details endpoint
      const response = await axiosPrivateInstanceFunction(BASE_URL).get(
        "/violation/vehicle-details",
        {
          params: { licensePlateNumber: vehicleNumber },
        }
      );

      // If the request is successful, return the data
      if (response.status === 200) {
        fulfill({ status: SUCCESS, data: response.data?.data });
      } else {
        // If the status is not 200, return a generic error
        fulfill({ status: FAILURE, message: SOMETHING_WENT_WRONG });
      }
    } catch (error) {
      // Handle different types of errors
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        if (error.response.status === 401) {
          fulfill({ status: FAILURE, message: ACCESS_TOKEN_EXPIRE });
        } else {
          fulfill({ status: FAILURE, message: SOMETHING_WENT_WRONG });
        }
      } else if (error.request) {
        // The request was made but no response was received
        fulfill({ status: FAILURE, message: NETWORK_ERROR });
      } else {
        // Something happened in setting up the request that triggered an Error
        fulfill({ status: FAILURE, message: SOMETHING_WENT_WRONG });
      }
    }
  });
};

export default fetchVehicleDetails;
