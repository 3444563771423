import { SOMETHING_WENT_WRONG } from ".";

const errorMap = new Map();

errorMap.set(1001, "User not found");
errorMap.set(1002, SOMETHING_WENT_WRONG);
errorMap.set(1003, "Otp is expired. Please resend otp");
errorMap.set(1004, "Invalid otp");
errorMap.set(1011, SOMETHING_WENT_WRONG);
errorMap.set(1013, "Invalid Credentials");
errorMap.set(4006, "No pending amount for user");

const getErrorMessage = (key) => {
  return errorMap.get(key);
};

export default getErrorMessage;
