/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: null,
};

export const referenceDataSlice = createSlice({
  name: "referenceData",
  initialState,
  reducers: {
    setReferenceData: (state, action) => {
      state.value = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setReferenceData } = referenceDataSlice.actions;

export default referenceDataSlice.reducer;
