import PropTypes from "prop-types";

function AdminCategory({ colorProp }) {
  return (
    <svg
      width="16"
      height="22"
      viewBox="0 0 16 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.72845 7.33261C2.8487 7.79631 3.03396 8.24068 3.27833 8.65262L3.42344 8.89681L2.70856 9.61409L4.13408 11.0396L4.84029 10.3268L5.08447 10.4719V10.4721C5.49643 10.7164 5.94097 10.9017 6.40449 11.0219L6.67943 11.0923L6.67961 12.1H8.69701V11.0925L8.97195 11.0221V11.0219C9.43565 10.9017 9.88003 10.7164 10.292 10.4721L10.5605 10.3268L11.2733 11.0396L12.6988 9.6141L11.986 8.90124L12.1311 8.65706H12.1313C12.3756 8.2451 12.5607 7.80075 12.6812 7.33704L12.7515 7.0621L13.7504 7.06192V5.0423H12.7429L12.6725 4.76736H12.6723C12.5521 4.30366 12.3668 3.85929 12.1225 3.44735L11.9774 3.20316L12.6902 2.49031L11.2645 1.05814L10.5605 1.77321L10.3163 1.6281V1.62791C9.90414 1.38354 9.45977 1.19828 8.99626 1.07804L8.72132 1.00769L8.72114 0H6.6927V1.00769L6.41776 1.07804C5.95407 1.19829 5.50969 1.38355 5.09775 1.62791L4.8403 1.77321L4.13627 1.05814L2.70853 2.48588L3.42139 3.19873L3.27627 3.44292H3.27609C3.03172 3.85487 2.84664 4.29923 2.72621 4.76293L2.65586 5.03787L1.65039 5.03805V7.05546H2.65808L2.72845 7.33261ZM7.70018 3.39228C8.40493 3.39228 9.08096 3.67237 9.57946 4.17071C10.0778 4.6692 10.3579 5.34524 10.3579 6.04998C10.3579 6.75473 10.0778 7.43076 9.57946 7.92925C9.08096 8.42756 8.40493 8.70768 7.70018 8.70768C6.99544 8.70768 6.3194 8.42759 5.82091 7.92925C5.3226 7.43076 5.04248 6.75473 5.04248 6.04998C5.04377 5.34542 5.32405 4.6701 5.82218 4.17198C6.3203 3.67386 6.99563 3.39355 7.70018 3.39228Z"
        fill={colorProp}
      />
      <path
        d="M7.69992 13.2002C5.65767 13.2002 3.69919 14.0114 2.25534 15.4555C0.811195 16.8995 0 18.858 0 20.9001V22.0001H15.3998V20.9001C15.3998 18.8579 14.5886 16.8994 13.1445 15.4555C11.7005 14.0114 9.74207 13.2002 7.69992 13.2002Z"
        fill={colorProp}
      />
    </svg>
  );
}
export default AdminCategory;
AdminCategory.propTypes = {
  colorProp: PropTypes.string,
};
AdminCategory.defaultProps = {
  colorProp: "#3C7CAF",
};
