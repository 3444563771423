import {
  STATION_DEPOSIT,
  COLLECTION,
  FINANCE,
  FINANCE_OVERVIEW,
  JUNCTION_VIDEOS,
  MAINTENANCE,
  OVERVIEW,
  PERSON_DATA,
  REJECTED_BY_POLICE,
  SIGNAL_CONTROL,
  SIGNAL_STATUS,
  SIGNAL_SUMMARY,
  TICKET_ANALYTICS,
  TICKET_DETAILS,
  TRAFFIC_ANALYTICS,
  TRAFFIC_VIOLATION,
  VEHICLE_VIDEO,
  VIDEO,
  VIDEO_JUNCTION,
  VIDEO_VEHICLE,
  VIOLATION_OVERVIEW,
  VIOLATION_POLICEREJECTION,
  VIOLATION_TRAFFICVIOLATION,
  BANK_DEPOSIT,
  FINANCE_CASH_DEPOSIT,
  FINANCE_BANK_DEPOSIT,
  FINANCE_COLLECTION_OVERVIEW,
  VEHICLE_DETAILS,
  VEHICLE_VEHICLE_DETAILS,
  VEHICLE_VIOLATION_DETAILS,
  VEHICLE_DETAILS_PERMISSION,
  UPDATE_VEHICLE_DETAILS_TAB,
  UPDATE_VEHICLE_DETAILS_PERMISSION,
  UPDATE_VEHICLE_DETAILS,
} from ".";

export const tabToResourceMapping = {
  [TRAFFIC_ANALYTICS]: {
    [TRAFFIC_VIOLATION]: VIOLATION_TRAFFICVIOLATION,
    [OVERVIEW]: VIOLATION_OVERVIEW,
    [REJECTED_BY_POLICE]: VIOLATION_POLICEREJECTION,
  },
  [FINANCE]: {
    [FINANCE_OVERVIEW]: FINANCE,
    [OVERVIEW]: FINANCE,
    [COLLECTION]: FINANCE_COLLECTION_OVERVIEW,
    [STATION_DEPOSIT]: FINANCE_CASH_DEPOSIT,
    [BANK_DEPOSIT]: FINANCE_BANK_DEPOSIT,
  },
  [MAINTENANCE]: {
    [SIGNAL_SUMMARY]: MAINTENANCE,
    [TICKET_ANALYTICS]: MAINTENANCE,
    [TICKET_DETAILS]: MAINTENANCE,
    [PERSON_DATA]: MAINTENANCE,
  },
  [VIDEO]: {
    [JUNCTION_VIDEOS]: VIDEO_JUNCTION,
    [VEHICLE_VIDEO]: VIDEO_VEHICLE,
  },
  [UPDATE_VEHICLE_DETAILS]: {
    [UPDATE_VEHICLE_DETAILS_TAB]: UPDATE_VEHICLE_DETAILS_PERMISSION,
  },
  [VEHICLE_DETAILS]: {
    [VEHICLE_VEHICLE_DETAILS]: VEHICLE_DETAILS_PERMISSION,
    [VEHICLE_VIOLATION_DETAILS]: VEHICLE_DETAILS_PERMISSION,
  },
};

export const resourceToDefaultTabMapping = {
  [TRAFFIC_ANALYTICS]: {
    [VIOLATION_OVERVIEW]: OVERVIEW,
    [VIOLATION_TRAFFICVIOLATION]: TRAFFIC_VIOLATION,
    [VIOLATION_POLICEREJECTION]: REJECTED_BY_POLICE,
  },
  [FINANCE]: {
    [FINANCE_COLLECTION_OVERVIEW]: COLLECTION,
    [FINANCE_CASH_DEPOSIT]: STATION_DEPOSIT,
    [FINANCE_BANK_DEPOSIT]: BANK_DEPOSIT,
    [FINANCE]: OVERVIEW,
  },
  [MAINTENANCE]: { [MAINTENANCE]: SIGNAL_SUMMARY },
  [VIDEO]: {
    [VIDEO_JUNCTION]: JUNCTION_VIDEOS,
    [VIDEO_VEHICLE]: VEHICLE_VIDEO,
  },
  [UPDATE_VEHICLE_DETAILS]: {
    [UPDATE_VEHICLE_DETAILS_PERMISSION]: UPDATE_VEHICLE_DETAILS_TAB,
  },
  [VEHICLE_DETAILS]: {
    [VEHICLE_DETAILS_PERMISSION]: VEHICLE_VEHICLE_DETAILS,
  },
};

export const routeTabResourcesList = {
  [TRAFFIC_ANALYTICS]: [
    VIOLATION_OVERVIEW,
    VIOLATION_TRAFFICVIOLATION,
    VIOLATION_POLICEREJECTION,
  ],
  [FINANCE]: [
    OVERVIEW,
    FINANCE_COLLECTION_OVERVIEW,
    FINANCE_CASH_DEPOSIT,
    FINANCE_BANK_DEPOSIT,
  ],
  [SIGNAL_STATUS]: [SIGNAL_CONTROL],
  [MAINTENANCE]: [MAINTENANCE],
  [VIDEO]: [VIDEO_JUNCTION, VIDEO_VEHICLE],
  [VEHICLE_DETAILS]: [VEHICLE_DETAILS_PERMISSION],
  [UPDATE_VEHICLE_DETAILS]: [UPDATE_VEHICLE_DETAILS_PERMISSION],
};
// ("violation-trafficviolation");
