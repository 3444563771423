import { useEffect } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { CrossSmIcon } from "../../assets/svg";
import TypographyCom from "./Typography";

const Container = styled.div`
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 1100;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  .pl16 {
    padding-left: 16px;
  }
  .pl24 {
    padding-left: 24px;
  }
`;
const ModalBox = styled.div`
  background-color: #fff;
  border-radius: 8px;
  width: ${(props) => props.width};
  max-height: 95vh;
`;

const Header = styled.div`
  height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 16px 0px 32px;
`;
const Svg = styled.div`
  width: 48px;
  height: 48px;
  margin-left: auto;
  &:hover {
    cursor: pointer;
  }
`;
const Body = styled.div`
  max-height: calc(95vh - 64px);
  overflow-y: auto;
  .cancelBtnBottom {
    width: 100%;
    height: 56px;
    margin-bottom: 12px;
  }
  overflow-y: scroll;
  * {
    -ms-overflow-style: none;
  }
  ::-webkit-scrollbar {
    display: none;
  }
`;

const Modal = ({ showModal, setShowModal, header, width, children }) => {
  useEffect(() => {
    if (showModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [showModal]);

  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  if (showModal) {
    return (
      <Container onClick={() => setShowModal(false)}>
        <ModalBox width={width} onClick={stopPropagation}>
          <Header>
            <>
              <TypographyCom className="fwB" modifiers="bt1">
                {header || null}
              </TypographyCom>
              <Svg className="dFA jcC" onClick={() => setShowModal(false)}>
                <CrossSmIcon />
              </Svg>
            </>
          </Header>
          <Body>
            <div>{children}</div>
          </Body>
        </ModalBox>
      </Container>
    );
  }
  return null;
};
export default Modal;

Modal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  header: PropTypes.element,
  width: PropTypes.string,
  children: PropTypes.element,
};

Modal.defaultProps = {
  width: "560px",
  header: <div />,
  children: <div />,
};
