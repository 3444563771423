import styled from "styled-components";

export const VehicleDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f7f8f9;
`;

export const VehicleSearchContainer = styled.div`
  position: relative;
  .search-icon {
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-35%);
  }
`;

export const VehicleSearchInput = styled.input`
  width: 806px;
  height: 56px;
  border: 1px solid #e0e0e0;
  padding: 16px 24px 16px 50px;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
`;

export const VehicleSearchButton = styled.button`
  width: 163px;
  height: 44px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  color: #333333;
  margin-top: 50px;
  background-color: ${({ theme }) => theme.colors.Secondary};
  color: #e0e0e0;
`;
