import PropTypes from "prop-types";

function VehicleDetail({ colorProp }) {
  const color = colorProp || "#fff";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="22"
      viewBox="0 0 24 22"
      fill="none"
    >
      <path
        d="M4.08566 0.300781C3.37236 1.0152 2.77898 1.60852 2.18529 2.20216C1.59729 2.79016 1.00871 3.37878 0.302246 4.08622H4.08566V0.300781Z"
        fill={color}
      />
      <path
        d="M7.25301 14.3115V13.8828C7.25301 13.3901 7.65068 12.9892 8.13946 12.9892H9.08236C9.0841 12.9892 9.08585 12.9892 9.08766 12.9892L9.80612 11.0983C10.1802 10.1144 11.0616 9.47851 12.0516 9.47851H15.5835V0H4.68171V4.38523C4.68171 4.54981 4.54827 4.68323 4.3837 4.68323H0V20.1492H7.93429L7.93464 17.2039C7.93472 16.6955 8.00842 15.9067 8.16987 15.3768L8.18995 15.3207L8.23833 15.2049H8.13946C7.65068 15.2049 7.25301 14.8042 7.25301 14.3115ZM3.36411 6.58868H11.9496C12.1142 6.58868 12.2476 6.72212 12.2476 6.88668C12.2476 7.05126 12.1142 7.18468 11.9496 7.18468H3.36411C3.19954 7.18468 3.0661 7.05126 3.0661 6.88668C3.0661 6.72212 3.19954 6.58868 3.36411 6.58868ZM3.36411 9.02858H10.138C10.3026 9.02858 10.436 9.16202 10.436 9.3266C10.436 9.49117 10.3026 9.62461 10.138 9.62461H3.36411C3.19954 9.62461 3.0661 9.49117 3.0661 9.3266C3.0661 9.16202 3.19954 9.02858 3.36411 9.02858ZM3.36411 11.4685H8.72454C8.88912 11.4685 9.02255 11.6019 9.02255 11.7665C9.02255 11.9311 8.88912 12.0645 8.72454 12.0645H3.36411C3.19954 12.0645 3.0661 11.9311 3.0661 11.7665C3.0661 11.6019 3.19954 11.4685 3.36411 11.4685ZM3.36411 13.9084H6.53237C6.69695 13.9084 6.83037 14.0418 6.83037 14.2064C6.83037 14.371 6.69695 14.5044 6.53237 14.5044H3.36411C3.19954 14.5044 3.0661 14.371 3.0661 14.2064C3.0661 14.0418 3.19954 13.9084 3.36411 13.9084ZM7.22944 16.9443H3.36411C3.19954 16.9443 3.0661 16.8109 3.0661 16.6463C3.0661 16.4817 3.19954 16.3483 3.36411 16.3483H7.22944C7.39401 16.3483 7.52744 16.4817 7.52744 16.6463C7.52744 16.8109 7.39401 16.9443 7.22944 16.9443Z"
        fill={color}
      />
      <path
        d="M23.6236 13.5849H22.6807C22.5327 13.5849 22.4056 13.6761 22.3485 13.8062L21.3998 11.3097C21.1145 10.5592 20.4518 10.0742 19.7115 10.0742H18.251H13.5121H12.0516C11.3113 10.0742 10.6485 10.5592 10.3633 11.3097L9.41461 13.8063C9.3575 13.6762 9.23033 13.5849 9.08234 13.5849H8.13937C7.9793 13.5849 7.849 13.7184 7.849 13.8825V14.3111C7.849 14.4751 7.9793 14.6085 8.13937 14.6085H8.95138C9.02792 14.6085 9.08074 14.6851 9.05353 14.7566L8.8736 15.2302L8.73995 15.55C8.60114 16.0057 8.53071 16.727 8.53065 17.2037L8.5302 19.1056V19.2826L8.53012 21.6437C8.53012 21.8401 8.68931 21.9993 8.88568 21.9993H10.5387C10.735 21.9993 10.8942 21.8401 10.8942 21.6437V20.8199H20.8688V21.6437C20.8688 21.8401 21.028 21.9993 21.2244 21.9993H22.8775C23.0738 21.9993 23.233 21.8401 23.233 21.6437V19.2825L23.2329 19.0834V18.9862L23.2325 17.1854L23.2321 17.1852C23.2304 16.7086 23.1603 16.0002 23.0232 15.55L22.8896 15.2302L22.7095 14.7566C22.6824 14.6851 22.7352 14.6085 22.8117 14.6085H23.6236C23.7837 14.6085 23.914 14.4751 23.914 14.3111V13.8825C23.914 13.7184 23.7837 13.5849 23.6236 13.5849ZM10.1616 14.2957L11.0829 11.7041C11.2562 11.1617 11.6435 10.8113 12.0693 10.8113H13.523H14.5152H17.2478H18.2401H19.6938C20.1196 10.8113 20.5068 11.1617 20.6802 11.704L21.6015 14.2957C21.7498 14.713 21.4507 15.1553 21.0201 15.1553H10.743C10.3125 15.1553 10.0133 14.713 10.1616 14.2957ZM11.7855 19.1056H10.5056C10.0045 19.1056 9.59832 18.6994 9.59832 18.1983V17.2777H10.5794C11.4172 17.2777 12.0964 17.9569 12.0964 18.7948C12.0964 18.9665 11.9573 19.1056 11.7855 19.1056ZM18.2675 18.8139C18.2675 19.1443 18.0287 19.4121 17.7343 19.4121H14.0937C13.7993 19.4121 13.5605 19.1443 13.5605 18.8139V17.8536C13.5605 17.5232 13.7993 17.2554 14.0937 17.2554H17.7343C18.0287 17.2554 18.2675 17.5232 18.2675 17.8536V18.8139ZM22.1648 18.1761C22.1648 18.6772 21.7586 19.0834 21.2575 19.0834H19.9776C19.8059 19.0834 19.6667 18.9443 19.6667 18.7726V18.5727C19.6667 17.8452 20.2565 17.2554 20.984 17.2554H22.1648V18.1761Z"
        fill={color}
      />
    </svg>
  );
}

VehicleDetail.propTypes = {
  colorProp: PropTypes.string,
};

VehicleDetail.defaultProps = {
  colorProp: "#1D2D4E",
};

export default VehicleDetail;
