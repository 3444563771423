import { configureStore } from "@reduxjs/toolkit";
import authenticationReducer from "./slices/AuthenticationSlice";
import currentNavReducer from "./slices/CurrentNavSlice";
import challanReducer from "./slices/ChallanSlice";
import referenceReducer from "./slices/ReferenceDataSlice";
import resourcesReducer from "./slices/ResourcesSlice";

const store = configureStore({
  reducer: {
    authentication: authenticationReducer,
    currentNav: currentNavReducer,
    challan: challanReducer,
    reference: referenceReducer,
    resources: resourcesReducer,
  },
});
export default store;
