import PropTypes from "prop-types";

function ArrowTopIcon({ colorProp }) {
  return (
    <svg
      width="10"
      height="8"
      viewBox="0 0 10 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 6.57227L5 2.00084L1 6.57227"
        stroke={colorProp}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
}
export default ArrowTopIcon;

ArrowTopIcon.propTypes = {
  colorProp: PropTypes.string,
};
ArrowTopIcon.defaultProps = {
  colorProp: "#000",
};
