import PropTypes from "prop-types";

function BottomTriangleArrow({ colorProp }) {
  return (
    <svg
      width="14"
      height="7"
      viewBox="0 0 14 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.14718 6.57148L0.988528 0.0208247L13.3688 0.0923864L7.14718 6.57148Z"
        fill={colorProp}
      />
    </svg>
  );
}
export default BottomTriangleArrow;

BottomTriangleArrow.propTypes = {
  colorProp: PropTypes.string,
};
BottomTriangleArrow.defaultProps = {
  colorProp: "#1D2D4E",
};
