import PropTypes from "prop-types";

function CopyIcon({ colorProp }) {
  return (
    <svg
      width="11"
      height="13"
      viewBox="0 0 11 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="3.5" y="0.5" width="7" height="9" rx="0.5" stroke={colorProp} />
      <path
        d="M1 6V11.25C1 11.8023 1.44772 12.25 2 12.25H7.25"
        stroke={colorProp}
        strokeLinecap="round"
      />
    </svg>
  );
}
export default CopyIcon;
CopyIcon.propTypes = {
  colorProp: PropTypes.string,
};

CopyIcon.defaultProps = {
  colorProp: "#0B74F0",
};
