import PropTypes from "prop-types";

function OperativeCategory({ colorProp }) {
  return (
    <svg
      width="22"
      height="16"
      viewBox="0 0 22 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.67402 9.51941C7.80267 10.0601 8.00029 10.5811 8.26062 11.0708L6.41445 11.0717C6.25405 11.0717 6.0987 11.1578 6.01759 11.3091L5.22163 12.7859H4.14833C3.96769 12.3254 3.51942 11.9991 2.99476 11.9991C2.31066 11.9991 1.75622 12.5537 1.75622 13.2376C1.75622 13.9217 2.31086 14.4761 2.99476 14.4761C3.51947 14.4761 3.96812 14.1499 4.14833 13.6893H5.49002C5.67267 13.6893 5.83005 13.5809 5.90105 13.4247L6.68287 11.9742H8.22139L7.91413 12.6656L9.37456 14.126L10.6133 13.576C11.2032 13.9391 11.847 14.2065 12.5208 14.3669L13.0075 15.6311H15.0729L15.5596 14.3667C16.2332 14.2063 16.8769 13.9393 17.4665 13.5762L18.7056 14.1266L20.1661 12.6662L19.6161 11.4275C19.9792 10.8376 20.2466 10.1938 20.4069 9.52004L21.6712 9.03338V6.96797L20.4067 6.4813C20.2463 5.80731 19.9793 5.16408 19.6162 4.57449L20.1666 3.33536L18.7062 1.87496L17.4674 2.42493C16.8776 2.06165 16.2338 1.79444 15.56 1.63407L15.0733 0.369875H13.0078L12.5211 1.63428C11.8471 1.79468 11.2039 2.06168 10.6139 2.42478L9.37471 1.87439L7.91428 3.33479L8.22154 4.02618H6.68302L5.9012 2.57567C5.8302 2.41952 5.67283 2.3111 5.49018 2.3111H4.14848C3.96785 1.85052 3.51958 1.52428 2.99491 1.52428C2.31081 1.52428 1.75637 2.07891 1.75637 2.76279C1.75637 3.44688 2.31102 4.0013 2.99491 4.0013C3.51963 4.0013 3.96828 3.67504 4.14848 3.21448H5.22179L6.01774 4.69129C6.09905 4.84259 6.2542 4.92835 6.41461 4.92876L8.26119 4.92957C8.00045 5.41926 7.80303 5.94034 7.67459 6.481L6.41016 6.96766V7.5486L2.72042 7.54799C2.53978 7.08742 2.09151 6.76117 1.56685 6.76117C0.882746 6.76117 0.328307 7.3158 0.328307 7.99968C0.328307 8.68377 0.882954 9.2382 1.56685 9.2382C2.09157 9.2382 2.54021 8.91193 2.72042 8.45137H6.40954V9.03252L7.67402 9.51941ZM10.1236 8.00014C10.1236 10.1634 11.8771 11.917 14.0405 11.917C16.2039 11.917 17.9574 10.1635 17.9574 8.00014C17.9574 5.83681 16.2039 4.08333 14.0405 4.08333C11.8771 4.08333 10.1236 5.83681 10.1236 8.00014Z"
        fill={colorProp}
      />
    </svg>
  );
}
export default OperativeCategory;
OperativeCategory.propTypes = {
  colorProp: PropTypes.string,
};
OperativeCategory.defaultProps = {
  colorProp: "#2F8A60",
};
