import { useEffect } from "react";
import styled, { ThemeProvider } from "styled-components";
import { useDispatch } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import theme from "./client/styles/theme";
import GlobalStyles from "./client/styles/globalStyles";
import {
  ACCESS_TOKEN,
  ACCESS_TOKEN_EXPIRE,
  FAILURE,
  REFRESH_TOKEN,
  RESOURCES,
  SOMETHING_WENT_WRONG,
  SUCCESS,
} from "./client/common/constants";
import { login } from "./client/redux/slices/AuthenticationSlice";
import WebRoutes from "./client/router/Router";
import { getReferenceData } from "./client/common/helpers/commonApis";
import { setReferenceData } from "./client/redux/slices/ReferenceDataSlice";
import { setResources } from "./client/redux/slices/ResourcesSlice";
import { logoutUser } from "./client/common/helpers";

const Wrapper = styled.div`
  max-width: 1560px;
  margin: auto;
`;
const App = () => {
  const dispatch = useDispatch();
  window.dispatchReduxUsingJs = dispatch;
  const accessToken = localStorage.getItem(ACCESS_TOKEN);
  useEffect(() => {
    if (accessToken) {
      dispatch(
        login({
          accessToken,
          refreshToken: localStorage.getItem(REFRESH_TOKEN),
        })
      );
    }
    if (localStorage.getItem(RESOURCES)) {
      dispatch(setResources(JSON.parse(localStorage.getItem(RESOURCES))));
    }
  }, [dispatch]);
  useEffect(() => {
    const fetchReferenceData = async () => {
      const response = await getReferenceData({
        referenceList: [
          "paymentSourceType",
          "violationsIncidentReviewStatusType",
          "violationsType",
          "violationsIncidentRejectReason",
          "violatorType",
        ],
      });
      if (response.status === SUCCESS) {
        dispatch(setReferenceData(response.data));
      } else if (
        response.status === FAILURE &&
        response.message === ACCESS_TOKEN_EXPIRE
      ) {
        logoutUser(dispatch);
      } else {
        console.log(SOMETHING_WENT_WRONG);
      }
    };
    fetchReferenceData();
  }, []);
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <Wrapper>
          <BrowserRouter>
            <WebRoutes />
          </BrowserRouter>
        </Wrapper>
      </ThemeProvider>
    </div>
  );
};

export default App;
