/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  challanId: null,
};

export const challanSlice = createSlice({
  name: "challan",
  initialState,
  reducers: {
    setChallanId: (state, action) => {
      state.challanId = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setChallanId } = challanSlice.actions;

export default challanSlice.reducer;
