import PropTypes from "prop-types";

function VideoIcon({ colorProp }) {
  const color = colorProp || "#fff";
  return (
    <svg
      width="21"
      height="15"
      viewBox="0 0 21 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.4022 0H4.59776C2.06294 0 0 2.05328 0 4.57623V10.4238C0 12.9467 2.06294 15 4.59776 15H16.4022C18.9371 15 21 12.9467 21 10.4238V4.57623C21 2.05328 18.9371 0 16.4022 0ZM13.5685 7.92049L9.04729 10.259C8.56553 10.5074 7.98988 10.1607 7.98988 9.61967V4.94262C7.98988 4.40164 8.56553 4.05492 9.04729 4.30328L13.5685 6.6418C14.0873 6.91229 14.0873 7.65246 13.5685 7.92049Z"
        fill={color}
      />
    </svg>
  );
}

VideoIcon.propTypes = {
  colorProp: PropTypes.string,
};

VideoIcon.defaultProps = {
  colorProp: "#1D2D4E",
};

export default VideoIcon;
