function SearchIcon() {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="6.75" cy="6.75" r="6.125" stroke="#848282" />
      <path
        d="M10.6875 6.75C10.6875 4.57538 8.92462 2.8125 6.75 2.8125"
        stroke="#848282"
      />
      <path
        d="M16.375 16.375L11.125 11.125"
        stroke="#848282"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default SearchIcon;
