function LogoutIcon() {
  //   const color = colorProp || "#1D2D4E";
  return (
    <svg
      width="29"
      height="29"
      viewBox="0 0 29 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="14.5" cy="14.5" r="14.5" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.8375 8.91699C13.2696 8.91699 11.9985 10.1993 11.9985 11.7812C11.9985 13.363 13.2696 14.6454 14.8375 14.6454C16.4054 14.6454 17.6765 13.363 17.6765 11.7812C17.6765 10.1993 16.4054 8.91699 14.8375 8.91699Z"
        fill="#1D2D4E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.8371 13.7642C12.5797 13.7642 10.644 15.2011 10.1518 17.1911C9.94763 18.0167 10.6586 18.6112 11.343 18.6112H18.3313C19.0157 18.6112 19.7267 18.0167 19.5225 17.1911C19.0302 15.2011 17.0946 13.7642 14.8371 13.7642Z"
        fill="#1D2D4E"
      />
    </svg>
  );
}
export default LogoutIcon;
