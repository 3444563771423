import PropTypes from "prop-types";

function ForwardIcon({ isDisable }) {
  const color = isDisable ? "#C5C7CC" : "#1D2D4E";
  return (
    <svg width="25" height="30" viewBox="0 0 25 30" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.27044e-07 1.24334C4.27044e-07 0.58349 0.364572 0.414574 0.818215 0.885273L10.942 11.3895L10.942 1.24354C10.942 0.583691 11.3065 0.414774 11.7602 0.885474L24.6598 14.2699C25.1134 14.7406 25.1134 15.4928 24.6598 15.9636L11.7602 29.348C11.3026 29.8227 10.942 29.6498 10.942 28.9899L10.942 18.8439L0.818215 29.3482C0.360674 29.8229 4.27044e-07 29.65 4.27044e-07 28.9901L4.27044e-07 1.24317V1.24334Z"
        fill={color}
      />
    </svg>
  );
}
export default ForwardIcon;
ForwardIcon.propTypes = {
  isDisable: PropTypes.bool,
};

ForwardIcon.defaultProps = {
  isDisable: false,
};
