import PropTypes from "prop-types";

function BalloonPointIcon({ colorProp }) {
  return (
    <svg
      width="10"
      height="16"
      viewBox="0 0 10 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.99995 0.711914C2.23884 0.711914 0 2.99894 0 5.79907C0 6.3768 0.247181 7.10071 0.612731 7.96615C0.978299 8.83172 1.47327 9.81624 1.99558 10.8002C3.04022 12.768 4.20857 14.7461 4.69084 15.6722C4.75016 15.7873 4.86963 15.8598 5 15.8598C5.13037 15.8598 5.24984 15.7873 5.30916 15.6722C5.7914 14.746 6.95965 12.768 8.00442 10.8002C8.52673 9.81624 9.01625 8.83172 9.38182 7.96615C9.74738 7.10058 10 6.37677 10 5.79907C10 2.99915 7.76268 0.711914 5.00005 0.711914H4.99995ZM4.99995 3.50823C5.98587 3.50823 6.78952 4.30921 6.78952 5.28823C6.78952 6.2659 5.98575 7.06823 4.99995 7.06823C4.01415 7.06823 3.20493 6.26593 3.20493 5.28823C3.20493 4.30937 4.014 3.50823 4.99995 3.50823Z"
        fill={colorProp}
      />
    </svg>
  );
}
export default BalloonPointIcon;
BalloonPointIcon.propTypes = {
  colorProp: PropTypes.string,
};

BalloonPointIcon.defaultProps = {
  colorProp: "#1D2D4E",
};
