function MiniArrowLeftIcon() {
  return (
    <svg
      width="12"
      height="21"
      viewBox="0 0 12 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.58884 10.5L11.5861 2.5028L9.78883 0.705566L0.892998 9.6014C0.396706 10.0977 0.396706 10.9023 0.892998 11.3986L9.78883 20.2945L11.5861 18.4972L3.58884 10.5Z"
        fill="black"
      />
    </svg>
  );
}
export default MiniArrowLeftIcon;
