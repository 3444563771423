/* eslint-disable no-useless-escape */

import { RESTRICTED_CONTENT_ID } from "../../../config";
import { logout } from "../../redux/slices/AuthenticationSlice";
import colors from "../../styles/theme/Color";
import { ACCESS_TOKEN, REFRESH_TOKEN, RESOURCES } from "../constants";
import {
  resourceToDefaultTabMapping,
  routeTabResourcesList,
} from "../constants/TabToResourceMapping";

const month = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];
const day = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thrusday",
  "Friday",
  "Saturday",
];

/**
 *
 * @param {Date} date
 * @returns date in dd MMM yyyy format
 */
export const convertDate = (date) => {
  const yyyy = date.getFullYear();
  const mm = date.getMonth(); // Months start at 0!
  let dd = date.getDate();
  if (dd < 10) dd = `0${dd}`;
  return `${dd} ${month[mm]} ${yyyy}`;
};

/**
 *
 * @param {Date} date
 * @returns time from the date object in hh:mm am/pm
 */
export const getTimeFromDate = (date) => {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  const ap = hours >= 12 ? "pm" : "am";
  hours %= 12;
  hours = hours || 12;
  minutes = minutes.toString().padStart(2, "0");
  return `${hours}:${minutes} ${ap}`;
};

/**
 *
 * @param {Date} date
 * @returns day of week
 */
export const getDayString = (date) => {
  return day[date.getDay()];
};

/**
 *
 * @param {string} status pending, approved or rejected
 * @returns color code
 */
export const handleTypeColorByStatus = (status) => {
  if (!status) return colors.Failure;
  switch (status.toLowerCase()) {
    case "approved":
      return colors.Success;
    case "pending":
      return colors.Message;
    case "rejected":
      return colors.Failure;
    default:
      return colors.Message;
  }
};

/**
 *
 * @param {string} status pending, deposited or rejected
 * @returns color code
 */
export const handleTypeColorByStatusBankDeposit = (status) => {
  if (!status) return colors.Failure;
  switch (status.toLowerCase()) {
    case "deposited":
      return { color: colors.Success, backgroundColor: "#DCEFDD" };
    case "pending":
      return { color: colors.Failure, backgroundColor: "#FFE6E9" };
    default:
      return { color: colors.Failure, backgroundColor: "#FFE6E9" };
  }
};

/**
 @description: wrapText wraps HTML canvas text onto a canvas of fixed width
 @param ctx - the context for the canvas we want to wrap text on
 @param text - the text we want to wrap.
 @param maxWidth - the width at which we want line breaks to begin - i.e. the maximum width of the canvas.
 @returns an array of [ lineText] for all lines
 */
export const wrapText = (ctx, text, maxWidth) => {
  const words = text.split(" ");
  let line = "";
  let testLine = "";
  const lineArray = [];

  for (let n = 0; n < words.length; n += 1) {
    // Create a test line, and measure it..
    testLine += `${words[n]} `;
    const metrics = ctx.measureText(testLine);
    const testWidth = metrics.width;
    if (testWidth > maxWidth && n > 0) {
      lineArray.push(line);
      line = `${words[n]} `;
      testLine = `${words[n]} `;
    } else {
      line += `${words[n]} `;
    }
    if (n === words.length - 1) {
      lineArray.push(line);
    }
  }
  return lineArray;
};

export const validateEmail = (email) => {
  const emailFormat = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
  if (!emailFormat.test(email)) {
    return false;
  }
  return true;
};
export const validatePhone = (phone) => {
  const phoneFormat = /^\d{10,11}$/;
  if (!phoneFormat.test(phone)) {
    return false;
  }
  return true;
};
export const validatePassword = (password) => {
  const PasswordFormat = /^[a-zA-Z0-9'@_!$^&#.\s]{6,28}$/;
  if (!PasswordFormat.test(password)) {
    return false;
  }
  return true;
};

export const parseJwt = (token) => {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        const jsonPart = `00${c.charCodeAt(0).toString(16)}`.slice(-2);
        return `%${jsonPart}`;
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
};

export const checkRegexForNumbers = (value) => {
  const regex = /^(\d+)?$/;
  return regex.test(value);
};
export const checkRegexForAlphabets = (value) => {
  const regex = /^([a-zA-Z]+)?$/;
  return regex.test(value);
};

export const getIdBySlug = (array, slug) => {
  const result = array.filter((value) => value.slug === slug);
  if (result) {
    return result[0].id;
  }
  return "";
};
export const getObjectById = (array, id) => {
  const result = array.filter((value) => value.id === id);
  if (result) {
    return result[0];
  }
  return "";
};
export const getObjectBySlug = (array, slug) => {
  const result = array.filter((value) => value.slug === slug);
  if (result) {
    return result[0];
  }
  return "";
};
export const logoutUser = (dispatch, navigate) => {
  dispatch(logout());
  localStorage.removeItem(ACCESS_TOKEN);
  localStorage.removeItem(REFRESH_TOKEN);
  localStorage.removeItem(RESOURCES);
  if (navigate) {
    navigate("/login");
  }
};

export const checkPermission = (resourceName) => {
  const resourceList = JSON.parse(localStorage.getItem(RESOURCES));
  if (!resourceList || resourceList.length === 0) {
    return false;
  }
  if (resourceList.includes(resourceName)) {
    return true;
  }
  return false;
};

function findCommonElement(array1, array2) {
  return array1.find((element) => array2.includes(element));
}

export const fetchDefaultPermissions = (route) => {
  const resourceList = JSON.parse(localStorage.getItem(RESOURCES));
  const defaultPermission = findCommonElement(
    routeTabResourcesList[route],
    resourceList
  );
  if (defaultPermission && resourceToDefaultTabMapping[route]) {
    return resourceToDefaultTabMapping[route][defaultPermission];
  }
  return null;
};

export const subtractDaysFromDate = (originalDate, daysToSubtract) => {
  const date = new Date(originalDate);
  date.setDate(date.getDate() - daysToSubtract + 1);
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  return date;
};

export const getDateDiffString = (fromDate, toDate) => {
  // Calculate the difference in milliseconds
  const diffMs = new Date(toDate) - new Date(fromDate);

  // Convert milliseconds to years, months, and days
  const msInDay = 1000 * 60 * 60 * 24;
  const msInYear = msInDay * 365.25; // Approximation for leap years

  const years = Math.floor(diffMs / msInYear);
  const remainingMs = diffMs - years * msInYear;
  const days = Math.floor(remainingMs / msInDay);

  // Construct the result string
  let result = "Last";
  if (years > 0) {
    result += ` ${years} year`;
  }
  if (days > 0) {
    result += ` ${days} days`;
  }

  return result.trim(); // Trim to remove extra space at the end
};

export const showRestrictedContent = (accessToken) => {
  const userId = parseJwt(accessToken).id;
  const restrictedUsers = RESTRICTED_CONTENT_ID.split(" ");
  if (restrictedUsers.includes(userId.toString())) {
    console.log("i'm here");
    return false;
  }
  return true;
};
