import PropTypes from "prop-types";

function SignalIcon({ colorProp }) {
  const color = colorProp || "#1D2D4E";
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none">
      <path
        d="M8.76831 1.78265C9.5383 0.450297 11.4616 0.4503 12.2316 1.78266L20.4571 16.0158C21.2277 17.3492 20.2655 19.0166 18.7255 19.0166H2.27439C0.73441 19.0166 -0.227793 17.3492 0.542759 16.0158L8.76831 1.78265Z"
        fill={colorProp ? "#1D2D4E" : "#fff"}
      />
      <path
        d="M12.1531 6.77851C12.1531 7.64836 11.4472 8.35351 10.5765 8.35351C9.70584 8.35351 9 7.64836 9 6.77851C9 5.90866 9.70584 5.20351 10.5765 5.20351C11.4472 5.20351 12.1531 5.90866 12.1531 6.77851Z"
        fill={color}
      />
      <path
        d="M12.1531 10.8505C12.1531 11.7203 11.4472 12.4255 10.5765 12.4255C9.70584 12.4255 9 11.7203 9 10.8505C9 9.98061 9.70584 9.27546 10.5765 9.27546C11.4472 9.27546 12.1531 9.98061 12.1531 10.8505Z"
        fill={color}
      />
      <path
        d="M12.1531 14.9224C12.1531 15.7923 11.4472 16.4974 10.5765 16.4974C9.70584 16.4974 9 15.7923 9 14.9224C9 14.0526 9.70584 13.3474 10.5765 13.3474C11.4472 13.3474 12.1531 14.0526 12.1531 14.9224Z"
        fill={color}
      />
    </svg>
  );
}
export default SignalIcon;

SignalIcon.propTypes = {
  colorProp: PropTypes.string,
};

SignalIcon.defaultProps = {
  colorProp: "#1D2D4E",
};
