import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
  ul {
    list-style: none;
  }
  figure,
  ul {
    padding: 0px;
    margin: 0px;
  }
  /**
  * 1. Correct the line height in all browsers.
  * 2. Prevent adjustments of font size after orientation changes in iOS.
  */

  html {
    -webkit-text-size-adjust: 100%; /* 2 */
  }

  /**
  * Remove the margin in all browsers.
  */

  html, body {
    height: 100%;
    margin: 0;
    font-family: 'Inter';
    font-size: 16px;
    line-height: 1.25;
    color: #333;
    background-color: #fff;
    -webkit-tap-highlight-color: transparent;
    @media screen and (max-width: 768px) {
      background-color:#fff;
      font-size: 14px;
    }
  }

  h1, h2, h3, h4, h5, h6 {
    margin: 0;
    font-size: inherit;
    font-weight: 500;
  }

  * {
    box-sizing: border-box;
  }

  *::after,*::before{
    box-sizing:border-box;
  }

  /**
  * Remove the gray background on active links in IE 10.
  */

  a {
    color:inherit;
    background-color: transparent;
    text-decoration: none;
  }

  b,
  strong {
    font-weight: bolder;
  }

  p {
    margin: 0;
  }

  img {
    border-style: none;
  }

  img::selection {
    background: none;
  }

  button,
  input,
  optgroup,
  select,
  textarea {
    font-family: inherit; /* 1 */
    font-size: 100%; /* 1 */
    line-height: 1.15; /* 1 */
    margin: 0; /* 2 */
  }

  /**
  * Show the overflow in IE.
  * 1. Show the overflow in Edge.
  */

  button,
  input {
    /* 1 */
    overflow: visible;
  }

  /**
  * Remove the inheritance of text transform in Edge, Firefox, and IE.
  * 1. Remove the inheritance of text transform in Firefox.
  */

  button,
  select {
    /* 1 */
    text-transform: none;
  }

  /**
  * Correct the inability to style clickable types in iOS and Safari.
  */

  button,
  [type='button'],
  [type='reset'],
  [type='submit'] {
    appearance: button;
    -webkit-appearance: button;
  }

  /**
  * Remove the inner border and padding in Firefox.
  */

  button::-moz-focus-inner,
  [type='button']::-moz-focus-inner,
  [type='reset']::-moz-focus-inner,
  [type='submit']::-moz-focus-inner {
    border-style: none;
    padding: 0;
  }

  /**
  * Restore the focus styles unset by the previous rule.
  */

  button:-moz-focusring,
  [type='button']:-moz-focusring,
  [type='reset']:-moz-focusring,
  [type='submit']:-moz-focusring {
    outline: 1px dotted ButtonText;
  }

  /**
  * Remove the default vertical scrollbar in IE 10+.
  */

  textarea {
    overflow: auto;
  }

  ::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit; 
  }

  input {
    appearance: none;
    -webkit-appearance: none;
  }

  input:-webkit-autofill,
  textarea:-webkit-autofill,
  select:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px #fff inset;
  }

  a, button {
    cursor: pointer;
  }

  /* Common class names to be added below */
  .Ell {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .dN{
    display:none;
  }
  .dB {
    display: block;
  }
  .dIB {
    display: inline-block;
  }
  .dF {
    display: flex;
  }
  .dIF {
    display: inline-flex;
  }
  .dFA {
    display: flex;
    align-items: center;
  }
  .f1 {
    flex: 1;
  }
  .fdC {
    flex-direction: column;
  }
  .fdR {
    flex-direction: row;
  }
  .fdrR {
    flex-direction: row-reverse;
  }
  .fs0 {
    flex-shrink: 0;
  }
  .fcB {
    color: #1d2d4e;
  }
  .fs40{
    font-size:40px;
  }
  .jcC {
    justify-content: center;
  }
  .jcR{
    justify-content: right;
  }
  .jcSA {
    justify-content: space-around;
  }
  .jcSB {
    justify-content: space-between;
  }
  .jcFE {
    justify-content: flex-end;
  }
  .aiFE {
    align-items: flex-end;
  }
  .aiFS {
    align-items: flex-start;
  }
  .aiB {
    align-items: baseline;
  }
  .aiC{
    align-items: center;
  }
  .boN {
    border: none;
  }
  .baW {
    background: white;
  }
  .br50 {
    border-radius: 50%;
  }
  .cD {
    cursor: default;
  }
  .cP {
    cursor: pointer;
  }
  .fw1000{
    font-weight: bolder !important;
  }
  .fwM{
    font-weight: 500 !important;
  }
  .fwB{
    font-weight: bold !important;
  }
  .fwW {
    flex-wrap: wrap;
  }
  .fwNW {
    flex-wrap: nowrap;
  }
  .pR{
    position: relative;
  }
  .pA{
    position: absolute;
  }
  .h0 {
    height: 0;
  }
  .h100 {
    height: 100%;
  }
  .h100VH {
    height: 100vh;
  }
  .hA {
    height: auto;
  }
  
  .o0 {
    opacity: 0;
  }
  .o1 {
    opacity: 1;
  }
  .ofC {
    object-fit: cover;
  }
  .oH {
    overflow: hidden;
  }
  .oxA {
    overflow-x: auto;
  }
  .oxH {
    overflow-x: hidden;
  }
  .oyA {
    overflow-y: auto;
  }
  .oyH {
    overflow-y: hidden;
  }
  .outN {
    outline: none;
  }
  .peN {
    pointer-events: none;
  }
  .peA {
    pointer-events: all;
  }
  .pA {
    position: absolute;
  }
  .pF {
    position: fixed;
  }
  .pR {
    position: relative;
  }
  .pS {
    position: static;
  }
  .r0 {
    right: 0;
  }
  .t0 {
    top: 0;
  }
  .l0 {
    left: 0;
  }
  .b0 {
    bottom: 0;
  }
  .bB{
    border-bottom: 1px solid #000;
  }
  .taC {
    text-align: center;
  }
  .taL {
    text-align: left;
  }
  .taR {
    text-align: right;
  }
  .ttC {
    text-transform: capitalize;
  }
  .ttU {
    text-transform: uppercase;
  }
  .ttN{
    text-transform: none !important;
  }
  .ttI{
    text-transform: inherit;
  }
  .vaM {
    vertical-align: middle;
  }
  .vH {
    visibility: hidden;
  }
  .w100 {
    width: 100%;
  }
  .wsN {
    white-space: nowrap;
  }
  .wosT {
    -webkit-overflow-scrolling: touch;
  }
  .zi0 {
    z-index: 0;
  }
  .zi1 {
    z-index: 1;
  }
  .link-text-hidden {
    position: fixed;
    left: -999px;
  }
  .m0{
    margin: 0;
  }
  .mt8{
    margin-top:8px !important;
  }
  .mt16{
    margin-top:16px !important;
  }
  .mt24{
    margin-top:24px !important;
  }
  .mt36{
    margin-top:36px !important;
  }
  .mt48{
    margin-top:48px !important;
  }
  .mt64{
    margin-top:64px !important;
  }
  .mb8{
    margin-bottom:8px !important;
  }
  .mb40{
    margin-bottom:40px !important;
  }
  .mlA{
    margin-left: auto;
  }
  .primary{
    color:${({ theme }) => theme.colors.Primary} !important
  }
  .error{
    color:${({ theme }) => theme.colors.Failure} !important;
  }
  .success {
    color:${({ theme }) => theme.colors.Success}  !important;
  }
  .desc{
    color:${({ theme }) => theme.colors.TextDescription} !important;
  }
  .message{
    color:${({ theme }) => theme.colors.Message} !important;
  }
  .loader{
    height: calc( 100vh - 80px);
    @media screen and (min-width: 768px) {
      height: calc( 100vh - 48px);
    } 
  }
  .threeDotLoader{
    display: flex;
    justify-content: center;
  }
  .leaflet-control-attribution{
    display: none !important;
  }
  /*
    Typography classnames to be used on elements like input/button or already defined elements
    To be used only if Typography component is not usable
  */
  .h1 {
    font-size: ${({ theme }) => theme.typeScale.Mobile.header1};
    @media screen and (min-width: 768px) {
      font-size: ${({ theme }) => theme.typeScale.Desktop.header1};
    }
  }
  .h2 {
    font-size: ${({ theme }) => theme.typeScale.Mobile.header2};
    @media screen and (min-width: 768px) {
      font-size: ${({ theme }) => theme.typeScale.Desktop.header2};
    }
  }
  .h3 {
    font-size: ${({ theme }) => theme.typeScale.Mobile.header3};
    @media screen and (min-width: 768px) {
      font-size: ${({ theme }) => theme.typeScale.Desktop.header3};
    }
  }
  .bt1 {
    font-size: ${({ theme }) => theme.typeScale.Mobile.bodyText1};
    @media screen and (min-width: 768px) {
      font-size: ${({ theme }) => theme.typeScale.Desktop.bodyText1};
    }
  }
  .bt2 {
    font-size: ${({ theme }) => theme.typeScale.Mobile.bodyText2};
    @media screen and (min-width: 768px) {
      font-size: ${({ theme }) => theme.typeScale.Desktop.bodyText2};
    }
  }
  .bt3 {
    font-size: ${({ theme }) => theme.typeScale.Mobile.bodyText3};
    @media screen and (min-width: 768px) {
      font-size: ${({ theme }) => theme.typeScale.Desktop.bodyText3};
    }
  }
`;

export default GlobalStyles;
