import axios from "axios";
import {
  SOMETHING_WENT_WRONG,
  FAILURE,
  ACCESS_TOKEN,
  REFRESH_TOKEN,
  RESOURCES,
  SUCCESS,
  NETWORK_ERROR,
} from "../../common/constants";
import { login } from "../../redux/slices/AuthenticationSlice";
import { BASE_URL } from "../../../config";
import { parseJwt } from "../../common/helpers";
import getErrorMessage from "../../common/constants/ErrorCodes";
import { setResources } from "../../redux/slices/ResourcesSlice";

export const loginApi = async ({
  email,
  phoneNumber,
  countryCode,
  password,
  dispatch,
}) => {
  return new Promise(async (fulfill) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${BASE_URL}/auth/login/password`,
        data: {
          email,
          phoneNumber,
          countryCode,
          password,
          source: "dashboard",
        },
      });
      if (
        response.data?.responseCode >= 200 &&
        response.data?.responseCode < 300 &&
        response.data?.data?.accessToken &&
        response.data?.data?.refreshToken
      ) {
        const jwtToken = parseJwt(response.data.data.accessToken);
        if (jwtToken?.resources) {
          localStorage.setItem(ACCESS_TOKEN, response.data.data.accessToken);
          localStorage.setItem(REFRESH_TOKEN, response.data.data.refreshToken);
          localStorage.setItem(RESOURCES, JSON.stringify(jwtToken.resources));
          dispatch(setResources(jwtToken.resources));
          dispatch(
            login({
              accessToken: response.data.data.accessToken,
              refreshToken: response.data.data.refreshToken,
            })
          );
        }
        fulfill({
          status: SUCCESS,
          data: response.data.data,
          message: SUCCESS,
        });
      } else {
        fulfill({ status: FAILURE, message: SOMETHING_WENT_WRONG });
      }
    } catch (error) {
      if (error.response) {
        if (
          error.response.data?.responseCode &&
          getErrorMessage(error.response.data?.responseCode)
        ) {
          fulfill({
            status: FAILURE,
            message: getErrorMessage(error.response.data?.responseCode),
          });
        } else {
          fulfill({ status: FAILURE, message: SOMETHING_WENT_WRONG });
        }
      } else if (error.request) {
        fulfill({ status: FAILURE, message: NETWORK_ERROR });
      } else {
        fulfill({ status: FAILURE, message: SOMETHING_WENT_WRONG });
      }
    }
  });
};

export const sendOtp = async ({ email, phoneNumber, countryCode }) => {
  return new Promise(async (fulfill) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${BASE_URL}/auth/forgot-password/send-otp`,
        data: {
          email,
          phoneNumber,
          countryCode,
          source: "dashboard",
        },
      });
      if (
        response.data?.responseCode >= 200 &&
        response.data?.responseCode < 300
      ) {
        fulfill({
          status: SUCCESS,
          data: response.data.data,
          message: SUCCESS,
        });
      }
    } catch (error) {
      if (error.response) {
        if (
          error.response.data?.responseCode &&
          getErrorMessage(error.response.data?.responseCode)
        ) {
          fulfill({
            status: FAILURE,
            message: getErrorMessage(error.response.data?.responseCode),
          });
        } else {
          fulfill({ status: FAILURE, message: SOMETHING_WENT_WRONG });
        }
      } else if (error.request) {
        fulfill({ status: FAILURE, message: NETWORK_ERROR });
      } else {
        fulfill({ status: FAILURE, message: SOMETHING_WENT_WRONG });
      }
    }
  });
};

export const validateOtp = async ({ email, phoneNumber, countryCode, otp }) => {
  return new Promise(async (fulfill) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${BASE_URL}/auth/forgot-password/validate-otp`,
        data: {
          email,
          phoneNumber,
          countryCode,
          otp,
          source: "dashboard",
        },
      });
      if (
        response.data?.responseCode >= 200 &&
        response.data?.responseCode < 300 &&
        response.data?.data?.tempToken
      ) {
        fulfill({
          status: SUCCESS,
          data: response.data.data,
          message: SUCCESS,
        });
      } else {
        fulfill({ status: FAILURE, message: SOMETHING_WENT_WRONG });
      }
    } catch (error) {
      if (error.response) {
        if (
          error.response.data?.responseCode &&
          getErrorMessage(error.response.data?.responseCode)
        ) {
          fulfill({
            status: FAILURE,
            message: getErrorMessage(error.response.data?.responseCode),
          });
        } else {
          fulfill({ status: FAILURE, message: SOMETHING_WENT_WRONG });
        }
      } else if (error.request) {
        fulfill({ status: FAILURE, message: NETWORK_ERROR });
      } else {
        fulfill({ status: FAILURE, message: SOMETHING_WENT_WRONG });
      }
    }
  });
};

export const resetPassword = async ({ accessToken, newPassword }) => {
  return new Promise(async (fulfill) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${BASE_URL}/auth/forgot-password/reset`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        data: {
          newPassword,
        },
      });
      if (
        response.data?.responseCode >= 200 &&
        response.data?.responseCode < 300
      ) {
        fulfill({
          status: SUCCESS,
          data: response.data.data,
          message: SUCCESS,
        });
      } else {
        fulfill({ status: FAILURE, message: SOMETHING_WENT_WRONG });
      }
    } catch (error) {
      if (error.response) {
        if (
          error.response.data?.responseCode &&
          getErrorMessage(error.response.data?.responseCode)
        ) {
          fulfill({
            status: FAILURE,
            message: getErrorMessage(error.response.data?.responseCode),
          });
        } else {
          fulfill({ status: FAILURE, message: SOMETHING_WENT_WRONG });
        }
      } else if (error.request) {
        fulfill({ status: FAILURE, message: NETWORK_ERROR });
      } else {
        fulfill({ status: FAILURE, message: SOMETHING_WENT_WRONG });
      }
    }
  });
};

export const refreshTokenApi = async ({ dispatch }) => {
  const refreshToken = localStorage.getItem(REFRESH_TOKEN);
  return new Promise(async (fulfill) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${BASE_URL}/auth/refresh`,
        headers: { Authorization: `Bearer ${refreshToken}` },
      });
      if (
        response.data?.responseCode >= 200 &&
        response.data?.responseCode < 300 &&
        response.data?.data?.accessToken &&
        response.data?.data?.refreshToken
      ) {
        const jwtToken = parseJwt(response.data.data.accessToken);
        if (jwtToken?.resources) {
          localStorage.setItem(ACCESS_TOKEN, response.data.data.accessToken);
          localStorage.setItem(REFRESH_TOKEN, response.data.data.refreshToken);
          localStorage.setItem(RESOURCES, JSON.stringify(jwtToken.resources));
          dispatch(setResources(jwtToken.resources));
          dispatch(
            login({
              accessToken: response.data.data.accessToken,
              refreshToken: response.data.data.refreshToken,
            })
          );
        }
        fulfill({
          status: SUCCESS,
          data: response.data.data,
          message: SUCCESS,
        });
      } else {
        fulfill({ status: FAILURE, message: SOMETHING_WENT_WRONG });
      }
    } catch (error) {
      if (error.response) {
        fulfill({ status: FAILURE, message: SOMETHING_WENT_WRONG });
      } else if (error.request) {
        fulfill({ status: FAILURE, message: NETWORK_ERROR });
      } else {
        fulfill({ status: FAILURE, message: SOMETHING_WENT_WRONG });
      }
    }
  });
};
