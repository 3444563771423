import axios from "axios";
import {
  ACCESS_TOKEN_EXPIRE,
  FAILURE,
  SUCCESS,
  NETWORK_ERROR,
  SOMETHING_WENT_WRONG,
} from "../constants";
import axiosPrivateInstance from "./axiosInstance";
import { BASE_URL } from "../../../config";

export const getCountries = async ({ resource }) => {
  return new Promise(async (fulfill) => {
    try {
      const response = await axiosPrivateInstance.get(
        "/auth/resource/countries",
        { params: { resource } }
      );
      fulfill({ status: SUCCESS, data: response.data.data });
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          fulfill({ status: FAILURE, message: ACCESS_TOKEN_EXPIRE });
        } else {
          fulfill({ status: FAILURE, message: SOMETHING_WENT_WRONG });
        }
      } else if (error.request) {
        fulfill({ status: FAILURE, message: NETWORK_ERROR });
      } else {
        fulfill({ status: FAILURE, message: SOMETHING_WENT_WRONG });
      }
    }
  });
};

export const getStates = async ({ resource, countryId }) => {
  return new Promise(async (fulfill) => {
    try {
      const response = await axiosPrivateInstance.get("/auth/resource/states", {
        params: { resource, countryId },
      });

      fulfill({ status: SUCCESS, data: response.data.data });
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          fulfill({ status: FAILURE, message: ACCESS_TOKEN_EXPIRE });
        } else {
          fulfill({ status: FAILURE, message: SOMETHING_WENT_WRONG });
        }
      } else if (error.request) {
        fulfill({ status: FAILURE, message: NETWORK_ERROR });
      } else {
        fulfill({ status: FAILURE, message: SOMETHING_WENT_WRONG });
      }
    }
  });
};
export const getCities = async ({ resource, stateId }) => {
  return new Promise(async (fulfill) => {
    try {
      const response = await axiosPrivateInstance.get("/auth/resource/cities", {
        params: { resource, stateId },
      });
      fulfill({ status: SUCCESS, data: response.data.data });
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          fulfill({ status: FAILURE, message: ACCESS_TOKEN_EXPIRE });
        } else {
          fulfill({ status: FAILURE, message: SOMETHING_WENT_WRONG });
        }
      } else if (error.request) {
        fulfill({ status: FAILURE, message: NETWORK_ERROR });
      } else {
        fulfill({ status: FAILURE, message: SOMETHING_WENT_WRONG });
      }
    }
  });
};
export const getRegions = async ({ resource, cityId }) => {
  return new Promise(async (fulfill) => {
    try {
      const response = await axiosPrivateInstance.get(
        "/auth/resource/regions",
        {
          params: { resource, cityId },
        }
      );

      fulfill({ status: SUCCESS, data: response.data.data });
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          fulfill({ status: FAILURE, message: ACCESS_TOKEN_EXPIRE });
        } else {
          fulfill({ status: FAILURE, message: SOMETHING_WENT_WRONG });
        }
      } else if (error.request) {
        fulfill({ status: FAILURE, message: NETWORK_ERROR });
      } else {
        fulfill({ status: FAILURE, message: SOMETHING_WENT_WRONG });
      }
    }
  });
};

export const getJunctions = async ({ resource, regionId }) => {
  return new Promise(async (fulfill) => {
    try {
      const response = await axiosPrivateInstance.get(
        "/auth/resource/intersections",
        {
          params: { resource, regionId },
        }
      );
      fulfill({ status: SUCCESS, data: response.data.data });
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          fulfill({ status: FAILURE, message: ACCESS_TOKEN_EXPIRE });
        } else {
          fulfill({ status: FAILURE, message: SOMETHING_WENT_WRONG });
        }
      } else if (error.request) {
        fulfill({ status: FAILURE, message: NETWORK_ERROR });
      } else {
        fulfill({ status: FAILURE, message: SOMETHING_WENT_WRONG });
      }
    }
  });
};

export const getReferenceData = async ({ referenceList }) => {
  return new Promise(async (fulfill) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${BASE_URL}/app/reference-data`,
        data: { referenceKeys: referenceList },
      });
      fulfill({ status: SUCCESS, data: response.data.data });
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          fulfill({ status: FAILURE, message: ACCESS_TOKEN_EXPIRE });
        } else {
          fulfill({ status: FAILURE, message: SOMETHING_WENT_WRONG });
        }
      } else if (error.request) {
        fulfill({ status: FAILURE, message: NETWORK_ERROR });
      } else {
        fulfill({ status: FAILURE, message: SOMETHING_WENT_WRONG });
      }
    }
  });
};
