import PropTypes from "prop-types";

import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { CrossIcon, SearchIcon } from "../../assets/svg";

const Container = styled.div`
  width: 400px;
  height: 44px;
  background: ${({ theme }) => theme.colors.Tertiary};
  border-radius: 6px;
  padding: 0 16px;
  float: right;
  input {
    border: none;
    flex: 1;
    background: ${({ theme }) => theme.colors.Tertiary};
    padding: 0 24px;
    color: ${({ theme }) => theme.colors.Quaternary};
    :focus {
      outline-width: 0;
    }
  }
  .icon {
    height: 17px;
    width: 17px;
  }
`;

/**
 * debouncing
 * @param {function} func
 * @param {int} wait
 * @param {*} immediate
 * @returns
 */
const debounce = (func, wait, immediate) => {
  let timeout;
  return function executedFunction(...args) {
    const context = this;
    const later = () => {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
};

const Searchbar = ({ searchText, setSearchText, searchPlaceholder }) => {
  const [text, setText] = useState(searchText || "");

  const updateSearchText = (value) => {
    setSearchText(value);
  };
  const debounceUpdateText = useMemo(() => {
    return debounce(updateSearchText, 300);
  }, []);

  /**
   * handle on text change on searchbar
   * set state and call debouncing
   * @param {object} event
   */
  const onTextChange = (val) => {
    setText(val);
    debounceUpdateText(val);
  };
  useEffect(() => {
    setText(searchText);
  }, [searchText]);
  return (
    <Container className="dFA">
      <div className="icon">
        <SearchIcon />
      </div>
      <input
        type="text"
        placeholder={searchPlaceholder}
        value={text}
        onChange={(e) => onTextChange(e.target.value)}
      />
      {text && (
        <div className="icon">
          <CrossIcon onClick={() => onTextChange("")} />
        </div>
      )}
    </Container>
  );
};

export default Searchbar;
Searchbar.propTypes = {
  searchText: PropTypes.string.isRequired,
  setSearchText: PropTypes.func.isRequired,
  searchPlaceholder: PropTypes.func,
};
Searchbar.defaultProps = {
  searchPlaceholder: "Search",
};
