import styled from "styled-components";

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 1000;
`;

export const HeaderSubContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 79px;
  background: #fff;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
  width: 100%;
  padding: 20px;
`;

export const HeaderRight = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const HeaderLeft = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const BackButton = styled.div`
  cursor: pointer;
`;
export const VehicleNumber = styled.div`
  font-size: 17px;
  font-weight: 600;
  margin-left: 20px;
`;

export const SearchInputContainer = styled.div`
  position: relative;
  .search-icon {
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-35%);
  }
  .clear-icon {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-35%);
  }
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const SearchInput = styled.input`
  width: 348px;
  height: 48px;
  border-radius: 6px;
  background: #f7f8f9;
  padding: 10px;
  padding-left: 50px;
  border: none;
  outline: none;
`;

export const TabContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #e0e0e0;
  height: 50px;
  width: 100%;
  padding: 20px;
  backdrop-filter: blur(10px);
`;

export const Tab = styled.div`
  cursor: pointer;
  margin-right: 80px;
`;
