/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { HOME } from "../../common/constants";

const initialState = {
  value: HOME,
};

export const currentNavSlice = createSlice({
  name: "currentNav",
  initialState,
  reducers: {
    currentNav: (state, action) => {
      state.value = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { currentNav } = currentNavSlice.actions;

export default currentNavSlice.reducer;
