import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import ReactLoading from "react-loading";
import { NicVehicleDetailsContainer, LoaderContainer } from "./style";
import fetchVehicleDetails from "../../services";
import { SUCCESS } from "../../../../common/constants";

const NicVehicleDetails = ({ vehicleNumber }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const FetchNicVehicleDetails = async () => {
    setLoading(true);
    const response = await fetchVehicleDetails(vehicleNumber);
    setLoading(false);
    if (response.status === SUCCESS) {
      console.log(response.data);
      if (!response.data) {
        setError("Record not found!");
      }
      setData(JSON.parse(response.data));
    } else {
      setError(response.message);
    }
  };

  useEffect(() => {
    FetchNicVehicleDetails();
  }, []);

  if (loading) {
    return (
      <LoaderContainer>
        <ReactLoading type="cylon" height={100} width={200} color="grey" />
      </LoaderContainer>
    );
  }
  console.log(vehicleNumber);
  return (
    <>
      {error ? (
        <div
          style={{
            color: "red",
            padding: "10px",
            margin: "20px 0",
            border: "1px solid red",
            borderRadius: "4px",
            backgroundColor: "#ffebee",
          }}
        >
          <p>Error: {error}</p>
        </div>
      ) : (
        <NicVehicleDetailsContainer>
          <pre id="nic-vehicle-details-container">
            {JSON.stringify(data, null, 2)}
          </pre>
        </NicVehicleDetailsContainer>
      )}
    </>
  );
};

NicVehicleDetails.propTypes = {
  vehicleNumber: PropTypes.string.isRequired,
};

export default NicVehicleDetails;
