import PropTypes from "prop-types";

function ArrowBottomIcon({ colorProp }) {
  return (
    <svg
      width="10"
      height="8"
      viewBox="0 0 10 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 1L5 5.57143L9 1"
        stroke={colorProp}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
}
export default ArrowBottomIcon;

ArrowBottomIcon.propTypes = {
  colorProp: PropTypes.string,
};
ArrowBottomIcon.defaultProps = {
  colorProp: "#000",
};
