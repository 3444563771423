import PropTypes from "prop-types";

function CrossIcon({ colorProp }) {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.195312 10.862L10.862 0.195312L11.8048 1.13812L1.13812 11.8048L0.195312 10.862Z"
        fill={colorProp}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.8047 10.862L1.13802 0.195312L0.19521 1.13812L10.8619 11.8048L11.8047 10.862Z"
        fill={colorProp}
      />
    </svg>
  );
}
export default CrossIcon;
CrossIcon.propTypes = {
  colorProp: PropTypes.string,
};

CrossIcon.defaultProps = {
  colorProp: "#848282",
};
