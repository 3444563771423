import React, { useState } from "react";
import PropTypes from "prop-types";
import Header from "./header";
import NicVehicleDetails from "./nicVehicleDetails";
import ViolationList from "./violationList";
import { VehicleDetailsNICContainer } from "./style";

function highlightText(searchTerm) {
  const content = document.getElementById(
    "nic-vehicle-details-container"
  ).innerHTML;

  // Reset the content to remove any previous highlights
  const regexRemoveHighlight =
    /<span style="background-color: yellow;">(.*?)<\/span>/g;
  const cleanContent = content.replace(regexRemoveHighlight, "$1");

  const regex = new RegExp(`(${searchTerm})`, "gi");
  // const highlightedText = cleanContent.replace(
  //   regex,
  //   `<span style="background-color: yellow;">${searchTerm}</span>`
  // );
  const highlightedText = cleanContent.replace(regex, (match) => {
    return `<span style="background-color: yellow;">${match}</span>`;
  });
  document.getElementById("nic-vehicle-details-container").innerHTML =
    highlightedText;
}

const VehicleDetailsNIC = ({
  vehicleNumber,
  setShowVehicleDetails,
  vehicleDetailsTab,
  setVehicleDetailsTab,
  violationDetailsTab,
  setViolationDetailsTab,
}) => {
  const [searchText, setSearchText] = useState("");

  const handleSearchTextChange = (e) => {
    setSearchText(e.target.value);
    if (vehicleDetailsTab) {
      highlightText(e.target.value);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };
  return (
    <VehicleDetailsNICContainer>
      <Header
        vehicleNumber={vehicleNumber}
        vehicleDetailsTab={vehicleDetailsTab}
        setVehicleDetailsTab={setVehicleDetailsTab}
        violationDetailsTab={violationDetailsTab}
        setViolationDetailsTab={setViolationDetailsTab}
        searchText={searchText}
        handleSearchTextChange={handleSearchTextChange}
        handleSubmit={handleSubmit}
      />
      {vehicleDetailsTab ? (
        <NicVehicleDetails
          vehicleNumber={vehicleNumber}
          setShowVehicleDetails={setShowVehicleDetails}
          vehicleDetailsTab={vehicleDetailsTab}
          setVehicleDetailsTab={setVehicleDetailsTab}
          violationDetailsTab={violationDetailsTab}
          setViolationDetailsTab={setViolationDetailsTab}
        />
      ) : (
        <ViolationList
          vehicleNumber={vehicleNumber}
          setShowVehicleDetails={setShowVehicleDetails}
          vehicleDetailsTab={vehicleDetailsTab}
          setVehicleDetailsTab={setVehicleDetailsTab}
          violationDetailsTab={violationDetailsTab}
          setViolationDetailsTab={setViolationDetailsTab}
        />
      )}
    </VehicleDetailsNICContainer>
  );
};

VehicleDetailsNIC.propTypes = {
  vehicleNumber: PropTypes.string.isRequired,
  setShowVehicleDetails: PropTypes.func.isRequired,
  vehicleDetailsTab: PropTypes.bool.isRequired,
  setVehicleDetailsTab: PropTypes.func.isRequired,
  violationDetailsTab: PropTypes.bool.isRequired,
  setViolationDetailsTab: PropTypes.func.isRequired,
};

export default VehicleDetailsNIC;
