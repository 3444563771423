import PropTypes from "prop-types";

function CrossIcon({ colorProp }) {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.19043 1.19043L15.8101 15.8101"
        stroke={colorProp}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M16 1.38525L0.999999 15.6145"
        stroke={colorProp}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
}
export default CrossIcon;
CrossIcon.propTypes = {
  colorProp: PropTypes.string,
};

CrossIcon.defaultProps = {
  colorProp: "#7B7979",
};
