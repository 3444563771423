import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import SidebarWrapper from "../../common/components/Sidebar";
import { currentNav } from "../../redux/slices/CurrentNavSlice";
import { VEHICLE_DETAILS } from "../../common/constants";
import SearchIcon from "../../common/assets/svg/searchIcon";
import VehicleDetailsNIC from "./components/vehicleDetailsNIC";
import {
  VehicleDetailsContainer,
  VehicleSearchContainer,
  VehicleSearchInput,
  VehicleSearchButton,
} from "./style";

const VehicleDetailContainer = () => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const [vehicleNumber, setVehicleNumber] = useState("");
  // const [isLoading, setIsLoading] = useState(false);
  // const [error, setError] = useState(null);
  // const [vehicleDetails, setVehicleDetails] = useState(null);
  const [showVehicleDetails, setShowVehicleDetails] = useState(false);
  const [vehicleDetailsTab, setVehicleDetailsTab] = useState(true);
  const [violationDetailsTab, setViolationDetailsTab] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("submit", vehicleNumber);
    setShowVehicleDetails(true);
    setVehicleDetailsTab(true);
    const updatedSearchParams = new URLSearchParams(searchParams.toString());
    updatedSearchParams.set("vehicleNumber", vehicleNumber);
    setSearchParams(updatedSearchParams);
  };

  const handleChange = (e) => {
    setVehicleNumber(e.target.value);
  };

  useEffect(() => {
    dispatch(currentNav(VEHICLE_DETAILS));
  }, []);

  useEffect(() => {
    if (searchParams.get("vehicleNumber")) {
      setVehicleNumber(searchParams.get("vehicleNumber"));
      setShowVehicleDetails(true);
      setVehicleDetailsTab(true);
      setViolationDetailsTab(false);
    } else {
      setShowVehicleDetails(false);
    }
  }, [searchParams]);

  return (
    <SidebarWrapper>
      {showVehicleDetails ? (
        <VehicleDetailsNIC
          vehicleNumber={vehicleNumber}
          setShowVehicleDetails={setShowVehicleDetails}
          vehicleDetailsTab={vehicleDetailsTab}
          setVehicleDetailsTab={setVehicleDetailsTab}
          violationDetailsTab={violationDetailsTab}
          setViolationDetailsTab={setViolationDetailsTab}
        />
      ) : (
        <VehicleDetailsContainer>
          <form
            onSubmit={handleSubmit}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <VehicleSearchContainer>
              <VehicleSearchInput
                value={vehicleNumber}
                onChange={handleChange}
                placeholder="Search by vehicle number"
              />
              <span className="search-icon">
                <SearchIcon />
              </span>
            </VehicleSearchContainer>
            <VehicleSearchButton type="submit" onSubmit={handleSubmit}>
              Search
            </VehicleSearchButton>
          </form>
        </VehicleDetailsContainer>
      )}
    </SidebarWrapper>
  );
};

export default VehicleDetailContainer;
