import PropTypes from "prop-types";

function HomeIcon({ colorProp }) {
  const color = colorProp || "white";
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none">
      <path
        d="M7.99996 0.846802L0 6.84095V16.8312H5.39998V12.6353C5.39998 11.1963 6.55958 10.0378 8 10.0378C9.44042 10.0378 10.6 11.1963 10.6 12.6353V16.8312H16V6.84095L7.99996 0.846802Z"
        fill={color}
      />
    </svg>
  );
}
export default HomeIcon;

HomeIcon.propTypes = {
  colorProp: PropTypes.string,
};

HomeIcon.defaultProps = {
  colorProp: "white",
};
