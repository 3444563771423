function ArrowFailureIcon() {
  return (
    <svg
      width="9"
      height="11"
      viewBox="0 0 9 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.20703 9.5L3.20703 0L5.20703 0L5.20703 9.5H3.20703Z"
        fill="#D7051E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.20696 8.58586L6.99985 5.79297L8.41406 7.20718L4.91406 10.7072C4.52354 11.0977 3.89037 11.0977 3.49985 10.7072L-0.000150681 7.20718L1.41406 5.79297L4.20696 8.58586Z"
        fill="#D7051E"
      />
    </svg>
  );
}
export default ArrowFailureIcon;
