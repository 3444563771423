/* eslint-disable no-param-reassign */

import axios from "axios";
import { BASE_URL } from "../../../config";
import { ACCESS_TOKEN, SUCCESS } from "../constants";
import { refreshTokenApi } from "../../components/Auth/service";

const accessToken = localStorage.getItem(ACCESS_TOKEN);
const axiosPrivateInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    Authorization: `Bearer ${accessToken}`,
  },
});

axiosPrivateInstance.interceptors.request.use(
  async (config) => {
    config.headers = {
      Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

axiosPrivateInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const prevRequest = error?.config;
    if (error?.response?.status === 401 && !prevRequest?.sent) {
      prevRequest.sent = true;
      const response = await refreshTokenApi({
        dispatch: window.dispatchReduxUsingJs,
      });
      if (response.status === SUCCESS) {
        prevRequest.headers.Authorization = `Bearer ${response?.data?.accessToken}`;
        return axiosPrivateInstance(prevRequest);
      }
    }
    return Promise.reject(error);
  }
);

export default axiosPrivateInstance;
