import PropTypes from "prop-types";

function UserAccessManagement({ colorProp }) {
  const color = colorProp || "#fff";
  return (
    <svg
      width="16"
      height="18"
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.00005 4.15385C4.00005 1.86934 5.80005 0 8.00005 0C10.2001 0 12.0001 1.86923 12.0001 4.15385C12.0001 6.43846 10.2001 8.30769 8.00005 8.30769C5.80005 8.30769 4.00005 6.43846 4.00005 4.15385ZM15.9668 18H0.0333333C0 17.7577 0 17.55 0 17.3077C0 12.7385 3.6 9 8 9C12.4 9 16 12.7385 16 17.3077C16 17.55 16.0001 17.7577 15.9668 18Z"
        fill={color}
      />
    </svg>
  );
}

UserAccessManagement.propTypes = {
  colorProp: PropTypes.string,
};

UserAccessManagement.defaultProps = {
  colorProp: "#1D2D4E",
};

export default UserAccessManagement;
