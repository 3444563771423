import PropTypes from "prop-types";

function ArrowLeftLgIcon({ colorProp }) {
  return (
    <svg width="23" height="16" viewBox="0 0 23 16" fill="none">
      <path
        d="M9.05882 1L2 8.05882L9.05882 14.5294"
        stroke={colorProp}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M2 8.05859H22"
        stroke={colorProp}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
}
export default ArrowLeftLgIcon;
ArrowLeftLgIcon.propTypes = {
  colorProp: PropTypes.string,
};

ArrowLeftLgIcon.defaultProps = {
  colorProp: "#3179E4",
};
