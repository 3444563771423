import PropTypes from "prop-types";

function ViolationIcon({ colorProp }) {
  const color = colorProp || "#1D2D4E";
  return (
    <svg width="27" height="27" viewBox="0 0 27 27" fill="none">
      <path
        d="M11.7684 2.99634C12.5384 1.66398 14.4616 1.66398 15.2316 2.99634L23.4572 17.2295C24.2277 18.5628 23.2655 20.2302 21.7255 20.2302H5.27445C3.73447 20.2302 2.77227 18.5628 3.54282 17.2295L11.7684 2.99634Z"
        fill={colorProp ? "#1D2D4E" : "#fff"}
      />
      <path
        d="M12.9941 13.768C12.7809 13.768 12.6024 13.6062 12.5817 13.3936L12.002 7.44827C11.9781 7.20448 12.1697 6.99316 12.4145 6.99316H15.08C15.3248 6.99316 15.5164 7.20448 15.4925 7.44827L14.9128 13.3936C14.8922 13.6062 14.7136 13.768 14.5004 13.768H12.9941Z"
        fill={color}
      />
      <path
        d="M13.6503 17.9825C12.7974 17.9825 12.1094 17.2929 12.1094 16.4379C12.1094 15.5828 12.7974 14.8656 13.6503 14.8932C14.5036 14.8932 15.1916 15.5828 15.1916 16.4379C15.1916 17.2929 14.5036 17.9825 13.6503 17.9825Z"
        fill={color}
      />
    </svg>
  );
}
export default ViolationIcon;
ViolationIcon.propTypes = {
  colorProp: PropTypes.string,
};

ViolationIcon.defaultProps = {
  colorProp: "#1D2D4E",
};
