import styled from "styled-components";

export const NicVehicleDetailsContainer = styled.div`
  overflow-y: scroll;
  color: #f58706;
  padding-left: 100px;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const BackButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
