import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { applyStyleModifiers } from "styled-components-modifiers";

const TYPO_MODIFIERS = {
  h1: ({ theme }) => css`
    font-size: ${theme.typeScale.Mobile.header1};
    @media screen and (min-width: 768px) {
      font-size: ${theme.typeScale.Desktop.header1};
    }
  `,
  h2: ({ theme }) => css`
    font-size: ${theme.typeScale.Mobile.header2};
    @media screen and (min-width: 768px) {
      font-size: ${theme.typeScale.Desktop.header2};
    }
  `,
  h3: ({ theme }) => css`
    font-size: ${theme.typeScale.Mobile.header3};
    @media screen and (min-width: 768px) {
      font-size: ${theme.typeScale.Desktop.header3};
    }
  `,
  bt1: ({ theme }) => css`
    font-size: ${theme.typeScale.Mobile.bodyText1};
    @media screen and (min-width: 768px) {
      font-size: ${theme.typeScale.Desktop.bodyText1};
    }
  `,
  bt2: ({ theme }) => css`
    font-size: ${theme.typeScale.Mobile.bodyText2};
    @media screen and (min-width: 768px) {
      font-size: ${theme.typeScale.Desktop.bodyText2};
    }
  `,
  bt3: ({ theme }) => css`
    font-size: ${theme.typeScale.Mobile.bodyText3};
    @media screen and (min-width: 768px) {
      font-size: ${theme.typeScale.Desktop.bodyText3};
    }
  `,
  regular: () => css`
    font-weight: 400;
  `,
  medium: () => css`
    font-weight: 500;
  `,
  bold: () => css`
    font-weight: bold;
  `,
};

const Typo = styled.h1`
  ${applyStyleModifiers(TYPO_MODIFIERS)}
`;

const TypographyCom = ({ tagName, children, modifiers, className = "" }) => {
  return (
    <Typo as={tagName} modifiers={modifiers} className={className}>
      {children}
    </Typo>
  );
};
export default TypographyCom;

TypographyCom.propTypes = {
  tagName: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    .isRequired,
  modifiers: PropTypes.string.isRequired,
  className: PropTypes.string,
};

TypographyCom.defaultProps = {
  className: "",
  tagName: "",
};
