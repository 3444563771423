import PropTypes from "prop-types";

function RewindIcon({ isDisable }) {
  const color = isDisable ? "#C5C7CC" : "#1D2D4E";

  return (
    <svg
      width="26"
      height="29"
      viewBox="0 0 26 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.0605 28.3743C25.0605 29.0342 24.696 29.2031 24.2423 28.7324L14.1186 18.2281V28.3741C14.1186 29.034 13.754 29.2029 13.3004 28.7322L0.400783 15.3478C-0.0528653 14.8771 -0.0528653 14.1248 0.400783 13.6541L13.3004 0.269679C13.7579 -0.205059 14.1186 -0.0320782 14.1186 0.627751V10.7737L24.2423 0.269479C24.6999 -0.20526 25.0605 -0.0322791 25.0605 0.62755V28.3745V28.3743Z"
        fill={color}
      />
    </svg>
  );
}
export default RewindIcon;

RewindIcon.propTypes = {
  isDisable: PropTypes.bool,
};

RewindIcon.defaultProps = {
  isDisable: false,
};
