function VerifiedIcon() {
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="11.5" cy="11.5" r="11.5" fill="#28882C" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.1848 7.30904L9.17241 17.3214L3.79459 11.5234L4.95544 10.4467L9.2153 15.0393L18.0652 6.18945L19.1848 7.30904Z"
        fill="white"
      />
    </svg>
  );
}
export default VerifiedIcon;
