import PropTypes from "prop-types";

function FinanceCategory({ colorProp }) {
  return (
    <svg
      width="20"
      height="22"
      viewBox="0 0 20 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.21809 6.31031C7.52767 6.9283 11.0104 7.9326 12.6359 6.15582C12.6359 6.15582 13.0229 3.14304 13.7969 1.98425C13.7969 1.98425 14.1839 0.748284 12.7907 1.21178C12.7907 1.21178 12.2489 1.44362 12.2489 0.670973C12.2489 -0.024365 11.3974 0.207476 11.0878 0.825471C10.7008 1.44345 10.1591 0.0530055 10.1591 0.0530055C10.1591 0.0530055 9.3077 -0.333328 8.61124 0.980001C8.61124 0.980001 6.98591 0.362019 7.14068 1.75247C7.21817 3.14296 5.36053 0.207489 5.43804 2.4478L7.21809 6.31031ZM10.2365 15.4259V18.0524C11.5523 17.8979 12.1714 15.8894 10.2365 15.4259ZM9.6947 11.9496C8.37892 12.1815 8.14682 13.9581 9.6947 14.3444V11.9496ZM13.0227 7.08269C11.6296 8.47318 8.22391 8.70498 6.44422 7.23719C6.44422 7.23719 -1.91437 10.1726 0.40746 18.9018C2.26488 23.6139 20.5303 22.9961 19.9882 16.7388C19.5238 11.4856 17.5116 9.47722 13.0227 7.08256V7.08269ZM7.60491 16.5846L8.45633 16.4301C8.61111 17.2026 8.92069 17.8979 9.6946 18.0524V15.2715C9.38503 15.1941 8.99796 15.0396 8.6111 14.808C7.14054 13.9581 7.52759 11.409 9.6946 11.1772V10.7909H10.2364V11.1772C11.3199 11.3317 12.0166 11.8725 12.1713 12.954L11.2426 13.1085C11.1651 12.4905 10.8556 12.1042 10.2364 11.9497V14.499C10.8556 14.6535 11.3974 14.7308 11.7843 15.1943C13.0226 16.3531 12.1714 18.6706 10.2364 18.7477V19.6747H9.6946V18.7477C8.45633 18.5932 7.68237 17.9752 7.60491 16.5848V16.5846Z"
        fill={colorProp}
      />
    </svg>
  );
}
export default FinanceCategory;
FinanceCategory.propTypes = {
  colorProp: PropTypes.string,
};
FinanceCategory.defaultProps = {
  colorProp: "#5E4B89",
};
